import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar/Navbar";
import contactImg from "../../assets/images/contact.jpg";
import Hero from "../../components/Hero/Hero";
import { PacmanLoader } from "react-spinners";
import Footer from "../../components/Footer/Footer";
import classes from "./Contact.module.scss";

const Contact = () => {
  const formRef = useRef();
  const [state, setState] = useState({
    name: "<Required>",
    email: "<Required>",
    phone: "<Not Entered>",
    message: "<Required>",
    sending: false,
  });

  const sendEmail = (e) => {
    e.preventDefault();

    setState((prevState) => {
      return {
        ...prevState,
        sending: true,
      };
    });

    setTimeout(() => {
      alert("Under Construction!");
      setState((prevState) => {
        return {
          ...prevState,
          sending: false,
        };
      });
    }, 2000);
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      setState((prevState) => {
        return {
          ...prevState,
          message: prevState.message + "<br />",
        };
      });
    }
  };

  const handleOnChange = (type, e) => {
    let placeholder = "<Required>";

    if (type === "phone") {
      placeholder = "<Not Entered>";
    }

    setState((prevState) => {
      return {
        ...prevState,
        [type]: e.target.value.length > 0 ? e.target.value : placeholder,
      };
    });
  };

  return (
    <div className={classes["contact"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero title={`Contact us`} image={contactImg} />
      <section className={classes["contact-section"]}>
        {/* <img src="" alt="" /> */}
        <div className={classes["container"]}>
          <h2>GET IN TOUCH WITH US</h2>
          <div className={classes["form-container"]}>
            <form
              className={classes["form-control"]}
              ref={formRef}
              onSubmit={sendEmail}
            >
              <input
                type="text"
                placeholder="Name"
                name="user_name"
                onChange={(e) => handleOnChange("name", e)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                name="user_email"
                onChange={(e) => handleOnChange("email", e)}
                required
              />
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                onChange={(e) => handleOnChange("phone", e)}
              />
              <textarea
                name="message"
                cols={30}
                rows={10}
                placeholder="Type a message..."
                onChange={(e) => handleOnChange("message", e)}
                onKeyDown={(e) => handleOnKeyDown(e)}
                required
              ></textarea>
              {!state.sending && (
                <button className={classes["send-btn"]} type="submit">
                  Send Message
                </button>
              )}
              {state.sending && (
                <div className={classes["loader"]}>
                  <p>Sending Email</p>
                  <PacmanLoader
                    color="#67066f"
                    size={15}
                    style={{
                      position: "relative",
                      left: "-30px",
                      display: "block",
                    }}
                  />
                </div>
              )}
            </form>
            <div className={classes["contact-details"]}>
              <div className={classes["placeholder"]}>
                Email: &nbsp;
                <a href="mailto:admin@unifiedbg.com.au">
                  Admin@unifiedbg.com.au
                </a>
              </div>
              <div className={classes["placeholder"]}>
                Phone Number: &nbsp;
                <a href="tel:1800824000">1800-UBG-000 (1800 824 000)</a>
              </div>
              <div className={classes["placeholder"]}>
                Address: &nbsp;
                <a
                  href="https://www.google.com/maps/dir//123+Epping+Rd+Macquarie+Park+NSW+2113/@-33.78406,151.1183696,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x6b12a7a9081591cf:0xcbd40c3940f109de"
                  target="_blank"
                  rel="noreferrer"
                >
                  Avaya House, Level 9, 123 Epping Rd, Macquarie Park, NSW 2113
                </a>
                <iframe
                  title="Get Directions"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.0945896658613!2d151.11349869410796!3d-33.78405544571854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a7a9081591cf%3A0xcbd40c3940f109de!2s123%20Epping%20Rd%2C%20Macquarie%20Park%20NSW%202113!5e0!3m2!1sen!2sau!4v1724385879896!5m2!1sen!2sau"
                  width="100%"
                  height="450"
                  style={{ border: 0, marginTop: "1rem" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
