import React from "react";
import classes from "../../../pages/Home/Home.module.scss";

const GridBox = ({ svg }) => {
  return (
    <div className={classes["grid-box"]}>
      <p>
        Unified Building Group offers professional diagnosis of building
        problems and provides honest, long-term, and economical solutions to
        owners, adhering to the highest provisions of the relevant Australian
        Standards and the Building Code of Australia. With extensive experience
        in strata and remedial building across Sydney, we focus on delivering
        quality projects with meticulous attention to detail.
      </p>
    </div>
  );
};

export default GridBox;
