import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import aboutUs from "../../assets/images/about.jpg";
import Footer from "../../components/Footer/Footer";
import nswTradingLicense from "../../assets/images/nsw-fair-trading.svg";
import memberEngineersAustralia from "../../assets/images/engineers-australia.png";
import masterBuilder from "../../assets/images/master-builders-member.png";
import iCare from "../../assets/images/icare.png";
import buildersCompensationFund from "../../assets/images/builders-compensation-fund.jpg";
import chaseUnderwriting from "../../assets/images/chase-underwriting.png";
import acra from "../../assets/images/acra.png";
import classes from "./About.module.scss";

const About = () => {
  return (
    <div className={classes["about"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero image={aboutUs} title={`About us`} />

      <div className={`${classes["meet-the-team"]}`}>
        <h1>Meet the team</h1>
        <div className={`${classes["team-box-wrapper"]}`}>
          <div className={`${classes["team-box"]}`}>
            <h2>Moji Shariati</h2>
            <p>
              Our Director, is a civil engineering graduate and a licensed
              builder. He has extensive knowledge in structural concrete
              repairs, with the ability to evaluate and assess the current
              deterioration of concrete structures and apply the appropriate
              diagnostic methods.
            </p>
          </div>
          <div className={`${classes["team-box"]}`}>
            <h2>George Farah</h2>
            <p>
              Has extensive experience and a deep understanding of the strata
              industry. He recognises that rectification works on any part of a
              strata plan require the utmost care, safety, and consideration for
              the residents. George is dedicated to minimising disruptions to
              their daily lives wherever possible.
            </p>
          </div>
        </div>
      </div>
      <div className={`${classes["our-credentials"]}`}>
        <h1>Our Credentials</h1>
        <div className={`${classes["our-credentials-logo-container"]}`}>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BUILDING LICENCE 278427C</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={memberEngineersAustralia} alt="" />
            <p>MEMBER ID: 5064521</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BODY CORPORATE REGISTRATION BUP0003949</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={masterBuilder} alt="" />
            <p>MEMBER ID: 3508168</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BUILDING PRACTIONER BUP0001885</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={acra} alt="" />
            <p>MEMBERSHIP NUMBER: 559</p>
            <br />
          </div>
        </div>
      </div>
      <div className={`${classes["insurances"]}`}>
        <h1>Insurances</h1>
        <div className={`${classes["insurances-logo-container"]}`}>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={iCare} alt="" />
            <p>APPROVED TO UNDERTAKE WORKS REQUIRING HOME</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={iCare} alt="" />
            <p>Worker's Compensation</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={buildersCompensationFund} alt="" />
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={chaseUnderwriting} alt="" />
            <p>Public liability and contract works</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
