import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar/Navbar";
import fieldImgOne from "../../assets/images/field-img-1.jpg";
import fieldImgTwo from "../../assets/images/field-img-2.jpg";
import fieldImgSix from "../../assets/images/field-img-6.jpg";
import fieldImgSeven from "../../assets/images/field-img-7.jpg";
import fieldImgEight from "../../assets/images/field-img-8.JPG";
import fieldImgNine from "../../assets/images/field-img-9.jpg";
import fieldImgTen from "../../assets/images/field-img-10.jpg";
import fieldImgEleven from "../../assets/images/field-img-11.jpg";
import fieldImgTwelve from "../../assets/images/field-img-12.jpg";
import fieldImgThirteen from "../../assets/images/field-img-13.jpg";
import fieldImgFourteen from "../../assets/images/field-img-14.jpg";
import fieldImgTwentyFour from "../../assets/images/field-img-24.jpg";
import fieldImgTwentyFive from "../../assets/images/field-img-25.jpg";
import fieldImgTwentySix from "../../assets/images/field-img-26.jpg";
import fieldImgTwentySeven from "../../assets/images/field-img-27.jpg";
import fieldImgTwentyEight from "../../assets/images/field-img-28.jpg";
import Hero from "../../components/Hero/Hero";
import Footer from "../../components/Footer/Footer";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CommitIcon from "@mui/icons-material/Commit";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import fieldImgThirtySix from "../../assets/images/field-img-36.jpg";
import fieldImgTwentyOne from "../../assets/images/field-img-21.jpg";
import fieldImgTwentyTwo from "../../assets/images/field-img-22.jpg";
import fieldImgNineteen from "../../assets/images/field-img-19.JPG";
import fieldImgForty from "../../assets/images/field-img-40.jpg";
import classes from "./Services.module.scss";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const displayRef = useRef(null);
  const navigate = useNavigate();
  const [state, setState] = useState({
    selected: 0,
  });

  const selectService = (index) => {
    setState((prevState) => {
      return {
        ...prevState,
        selected: index,
      };
    });
    displayRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const servicesOffered = [
    {
      title: `Waterproofing`,
      description: `In strata buildings, water ingress is a major concern. Leaks from inadequate or lack of waterproofing membrane which can lead to significant damage, necessitating swift and thorough repairs. Window & door replacements and replacing windows and doors in strata buildings is essential for maintaining security, energy efficiency, and aesthetic appeal. Over time, worn or damaged fixtures can compromise the integrity of the building, requiring expert attention to ensure optimal performance and appearance.`,
      icon: <WaterDropIcon className={classes["icon"]} />,
      images: [fieldImgThirtySix, fieldImgTwentyFour, fieldImgForty],
    },
    {
      title: `Balustrade Replacements and upgrades`,
      description: `Balustrade replacements and upgrades are vital for ensuring safety and compliance in strata buildings. Over time, balustrades can deteriorate, posing risks that necessitate professional intervention to restore structural integrity and enhance the overall appearance.`,
      icon: <AccountBalanceIcon className={classes["icon"]} />,
      images: [fieldImgOne, fieldImgTwo],
    },
    {
      title: `Spalling`,
      description: `Spalling is a common issue in strata buildings, where concrete surfaces begin to crack and flake caused from water ingress. This problem can weaken the structural integrity of the building, requiring immediate attention and repair to prevent further deterioration and ensure long-term stability.`,
      icon: <BusinessIcon className={classes["icon"]} />,
      images: [
        fieldImgNineteen,
        fieldImgSeven,
        fieldImgTwentyOne,
        fieldImgTwentyTwo,
      ],
    },
    {
      title: `Structural`,
      description: `Structural repairs are critical in maintaining the safety and longevity of strata buildings. Addressing issues like foundation cracks, load-bearing walls, and other structural components promptly ensures the building remains secure and stable for all residents.`,
      icon: <EngineeringIcon className={classes["icon"]} />,
      images: [fieldImgSix, fieldImgEight, fieldImgNine, fieldImgTen],
    },
    {
      title: `Lintel replacement & Brick Stitching`,
      description: `Lintel replacement is necessary when the supporting metals above doors and windows become corroded. Replacement of lintels over doors and windows will minimise damage to building elements and prolong the life of the structure.`,
      icon: <CommitIcon className={classes["icon"]} />,
      images: [
        fieldImgEleven,
        fieldImgTwelve,
        fieldImgThirteen,
        fieldImgFourteen,
      ],
    },
    {
      title: `Cavity Flashing Work`,
      description: ``,
      icon: <ViewModuleIcon className={classes["icon"]} />,
      images: [
        fieldImgTwentyFive,
        fieldImgTwentySix,
        fieldImgTwentySeven,
        fieldImgTwentyEight,
      ],
    },
  ];

  return (
    <div className={classes["services"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services | Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero title={`Our Services`} image={fieldImgOne} />
      <div className={classes["services-offered"]}>
        <h1>Services Offered</h1>
        <div className={classes["services-grid"]}>
          {servicesOffered.map((service, index) => {
            return (
              <div
                className={`${classes["service"]} ${
                  state.selected === index ? classes["selected"] : ""
                }`}
                key={index}
                onClick={() => selectService(index)}
              >
                <h2>{service.title}</h2>
                {service.icon}
              </div>
            );
          })}
        </div>
        <div className={classes["services-display"]} ref={displayRef}>
          {state.selected !== null && (
            <>
              <h2>{servicesOffered[state.selected].title}</h2>
              <p>{servicesOffered[state.selected].description}</p>
              <div className={classes["image-container"]}>
                {servicesOffered[state.selected].images &&
                  servicesOffered[state.selected].images.map((image, index) => {
                    return <img key={index} src={image} alt="" />;
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={`${classes["section-full-left"]}`}>
        <div className={`${classes["section-full-left-text-col"]}`}>
          <h1>Other services include:</h1>

          <ul>
            <li>Cavity Flashings</li>
            <li>Water Leaking Detection</li>
            <li>Joint Sealant Rendering</li>
            <li>Leaking Bathrooms</li>
            <li>Leaking Planter Boxes</li>
            <li>Façade Rectifications & Modifications</li>
            <li>Roof Repairs & Restoration</li>
            <li>Fire Protection Services</li>
            <li>External & Internal Painting</li>
          </ul>

          <button
            className={classes["btn"]}
            onClick={() => {
              navigate("/contact");
            }}
          >
            <span>Get in touch!</span>
          </button>
        </div>
        <div className={`${classes["section-full-left-img-col"]}`}>
          <img src={fieldImgOne} alt="field project" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
