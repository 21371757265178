import React, { useEffect, useState } from "react";
import classes from "./Hero.module.scss";

const Hero = ({ image, title, slogan }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = useState({
    x: 2.2,
    y: 2.2,
    mt: -60,
    transition: -200,
    transition2: -200,
    opacity: 0.2,
  });

  const handleNavigation = (e) => {
    let position = 1.2 + window.scrollY / 1000;

    if (position > 2.2) {
      position = 2.2;
    }

    if (position < 1.5) {
      position = 1.5;
    }

    setState((prevState) => {
      return {
        ...prevState,
        x: position,
        y: position,
      };
    });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          x: 1.5,
          y: 1.5,
          mt: -50,
          transition: 0,
          opacity: 0.5,
        };
      });
      setTimeout(() => {
        setState((prevState) => {
          return {
            ...prevState,
            transition2: 0,
          };
        });
      }, 1000);
    }, 1000);
    return () => {
      //cleanup
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, []);

  return (
    <div className={classes["hero"]}>
      <div
        className={classes["hero-filter"]}
        style={{ opacity: `${state.opacity}` }}
      ></div>
      <h1
        className={`${classes["hero-header"]} ${
          !slogan || slogan.length === 0 ? classes["hero-no-slogan"] : ""
        }`}
        style={{
          transform: `translateX(${state.transition}%)`,
          letterSpacing: "2px",
        }}
      >
        {title}
      </h1>
      <p
        className={`${classes["hero-slogan"]}`}
        style={{ transform: `translateX(${state.transition2}%)` }}
      >
        {slogan}
      </p>
      <img
        src={image}
        alt=""
        style={{
          willChange: "transform",
          transform: ` scale3d(${state.x}, ${state.y}, 1) rotate(1deg)`,
          transformStyle: "preserve-3d",
          transition: "transform 1s",
          top: state.mt + "px",
        }}
      />
    </div>
  );
};

export default Hero;
