import logo from "../../assets/images/logo-light.png";
import mba from "../../assets/images/mba-member-badge.jpg";
import MapIcon from "@mui/icons-material/Map";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={classes["footer"]}>
      <div className={classes["images-row"]}>
        <img className={classes["logo-img"]} src={logo} alt="business logo" />
        <img src={mba} alt="" />
        <div className={classes["contact-col"]}>
          <p>
            <EmailIcon />
            <a href="mailto:Admin@unifiedbg.com.au">Admin@unifiedbg.com.au</a>
          </p>
          <p>
            <LocalPhoneIcon /> <a href={"tel:1800824000"}>1800 824 000</a>
          </p>
          <p>
            <MapIcon />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/dir//123+Epping+Rd+Macquarie+Park+NSW+2113/@-33.78406,151.1183696,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x6b12a7a9081591cf:0xcbd40c3940f109de"
            >
              Avaya House, Level 9, 123 Epping Rd, Macquarie Park, NSW 2113
            </a>
          </p>
        </div>
      </div>
      <div className={classes["bottom-bar"]}>
        <div className={classes["bottom-bar-left"]}>
          United Building Group Copyright {new Date().getFullYear().toString()}
        </div>
        <div className={classes["bottom-bar-right"]}>
          Powered by Cliquenext Web Solutions
        </div>
      </div>
    </div>
  );
};

export default Footer;
