import { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { AnimatePresence, motion } from "framer-motion";
import classes from "./ImageGallery.module.scss";

const ImageGallery = ({ images }) => {
  const [state, setState] = useState({
    image: "",
    index: 0,
  });

  //   useEffect(() => {
  //     setState((prevState) => {
  //         return {
  //             ...prevState,

  //         }
  //     })
  //   }, [images])

  const viewImage = (image, index) => {
    setState((prevState) => {
      return {
        ...prevState,
        image: image.src,
        index: index,
      };
    });
  };

  const action = (a) => {
    let i = state.index;
    switch (a) {
      case "next-img":
        setState((prevState) => {
          return {
            ...prevState,
            image: images[(i + 1) % images.length].src,
            index: (i + 1) % images.length,
          };
        });
        break;
      case "prev-img":
        setState((prevState) => {
          return {
            ...prevState,
            image:
              i !== 0
                ? images[(i - 1) % images.length].src
                : images[images.length - 1].src,
            index: i !== 0 ? (i - 1) % images.length : images.length - 1,
          };
        });
        break;
      case "close":
        setState((prevState) => {
          return {
            ...prevState,
            index: 0,
            image: "",
          };
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {state.image.length > 0 && (
        <div className={classes["modal"]}>
          <div
            className={classes["overlay"]}
            onClick={() => action("close")}
          ></div>
          <button
            className={classes["closebtn"]}
            onClick={() => action("close")}
          >
            X
          </button>
          <button
            className={classes["nextbtn"]}
            onClick={() => action("next-img")}
          >
            <NavigateNextIcon className={classes["nextbtnicon"]} />
          </button>

          <AnimatePresence initial={false} mode="wait">
            <motion.img
              alt="slides"
              src={state.image}
              key={state.index}
              animate={{
                opacity: [0, 0.5, 1],
              }}
            />
          </AnimatePresence>

          <button
            className={classes["prevbtn"]}
            onClick={() => action("prev-img")}
          >
            <NavigateBeforeIcon className={classes["prevbtnicon"]} />
          </button>
        </div>
      )}
      <div className={classes["image-gallery"]}>
        {images.map((image, i) => (
          <img
            key={i}
            src={image.src}
            alt="unified building group"
            onClick={() => viewImage(image, i)}
          />
        ))}
      </div>
    </>
  );
};

export default ImageGallery;
