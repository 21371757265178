import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Hero from "../../../components/Hero/Hero";
import Footer from "../../../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import ImageGallery from "../../../components/ImageGallery/ImageGallery";
import classes from "./Project.module.scss";

const Project = () => {
  const navigate = useNavigate();
  const { project } = useLocation().state;
  const [state, setState] = useState({
    index: -1,
    images: [],
  });

  // eslint-disable-next-line
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        images: [...project.images],
      };
    });

    return () => {
      setState({
        index: -1,
        images: [],
      });
    };
  }, [project.images]);

  const goBack = () => {
    navigate("/projects");
  };

  return (
    <div className={classes["project"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{project.title} | Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero image={project.img} title={project.title} />
      <div className={classes["description"]}>
        {project.completed && <h2>Year Completed: {project.completed}</h2>}
        {project.projectValue && <h3>Project Value: {project.projectValue}</h3>}
        {project.description && <p>{project.description}</p>}
        {project.bulletPoints.length > 0 && (
          <ul>
            {project.bulletPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        )}
        <button onClick={goBack}>View Projects</button>
      </div>
      <div className={classes["gallery"]}>
        <ImageGallery images={state.images} />
      </div>
      <Footer />
    </div>
  );
};

export default Project;
