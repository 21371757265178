import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import img1 from "../../assets/images/homepage-general.jpg";
import acra from "../../assets/images/acra.png";
import fieldImgThree from "../../assets/images/field-img-3.jpg";
import { useNavigate } from "react-router-dom";
import fieldImgFiftySeven from "../../assets/images/field-img-57.jpg";
import fieldImgTwo from "../../assets/images/field-img-2.jpg";
import fieldImgSeven from "../../assets/images/field-img-7.jpg";
import nswTradingLicense from "../../assets/images/nsw-fair-trading.svg";
import masterBuilder from "../../assets/images/master-builders-member.png";
import memberEngineersAustralia from "../../assets/images/engineers-australia.png";
import classes from "./Home.module.scss";
import GridBox from "../../components/Home/GridBox/GridBox";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className={classes["home"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero
        image={fieldImgThree}
        title={`Unified Building Group`}
        slogan={`Remediate, Restore, Revitalise`}
      />
      <div className={`${classes["section"]} ${classes["section-white"]}`}>
        <div className={`${classes["section-left"]}`}>
          <h1>Over 30 Years of experience</h1>
          <span>
            Unified Building Group Pty Limited is an Australian privately owned
            company operating in NSW with over 30 years of combined experience
            and a qualified team of project managers and tradesmen. Our
            experienced, multi-skilled team covers all trades, and our regular
            training ensures we maintain the highest standards.
          </span>

          <span>
            Offering professional diagnosis of building problems and providing
            honest, long-term, and economical solutions to owner builders
          </span>
          <button
            className={classes["btn"]}
            onClick={() => {
              navigate("/about");
            }}
          >
            <span>About us</span>
          </button>
        </div>
        <div className={`${classes["section-right"]}`}>
          <img src={img1} alt="" />
        </div>
      </div>

      <div className={`${classes["our-credentials"]}`}>
        <div className={`${classes["our-credentials-logo-container"]}`}>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BUILDING LICENCE 278427C</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={memberEngineersAustralia} alt="" />
            <p>MEMBER ID: 5064521</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BODY CORPORATE REGISTRATION BUP0003949</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={masterBuilder} alt="" />
            <p>MEMBER ID: 3508168</p>
            <br />
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={nswTradingLicense} alt="" />
            <p>BUILDING PRACTIONER BUP0001885</p>
          </div>
          <div className={`${classes["img-wrapper"]}`}>
            <img src={acra} alt="" />
            <p>MEMBERSHIP NUMBER: 559</p>
            <br />
          </div>
        </div>
      </div>
      <div className={`${classes["section"]} ${classes["section-black"]}`}>
        <h1 className={classes["black-header"]}>Why Unified Building Group?</h1>
        <div className={classes["grid-wrap"]}>
          <GridBox svg={1} />
        </div>
      </div>
      <div className={`${classes["section-full-left"]}`}>
        <div className={`${classes["section-full-left-text-col"]}`}>
          <h1>Making your goals a reality</h1>
          <p>
            Understanding that we often work in people’s homes, we prioritise
            the little things—maintaining appropriate conversations and
            respecting our clients’ living spaces. These small gestures reflect
            our commitment to excellence and respect.
          </p>
        </div>
        <div className={`${classes["section-full-left-img-col"]}`}>
          <img src={fieldImgTwo} alt="project" />
        </div>
      </div>
      <div className={`${classes["section-full-right"]}`}>
        <div className={`${classes["section-full-right-img-col"]}`}>
          <img src={fieldImgSeven} alt="project" />
        </div>
        <div className={`${classes["section-full-right-text-col"]}`}>
          <h1>With you at every step</h1>
          <p>
            Built on principles of quality and collaboration, we work closely
            with owners, consultants, and stakeholders to bring their vision to
            life smoothly and efficiently. Our projects are crafted with care,
            paying attention to the details that matter.
          </p>
        </div>
      </div>
      <div className={`${classes["section-full-left"]}`}>
        <div
          className={`${classes["section-full-left-text-col"]} ${classes["section-full-left-text-col-center"]}`}
        >
          <h1>Check out more of our work</h1>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa,
            facere rem consequatur temporibus odit tenetur mollitia saepe
            aspernatur asperiores nihil rerum hic deleniti doloremque, molestias
            assumenda quas maiores dolore aut!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa,
            facere rem consequatur temporibus.
          </p> */}
          <button
            className={classes["btn"]}
            onClick={() => {
              navigate("/projects");
            }}
          >
            <span>Our Projects</span>
          </button>
        </div>
        <div className={`${classes["section-full-left-img-col"]}`}>
          <img src={fieldImgFiftySeven} alt="field project" />
        </div>
      </div>
      <div className={`${classes["section-center-text"]}`}>
        <div className={`${classes["section-center-text-border"]}`}>
          <div className={`${classes["diamond"]}`}></div>
          <h3>
            Eliminating the hassle from the way people approach building and
            design, matching properties to suit every need
          </h3>
          <div className={`${classes["diamond"]}`}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
