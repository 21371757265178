import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/Navbar/Navbar";
import classes from "./Projects.module.scss";
import Hero from "../../components/Hero/Hero";
import fieldImgFiftyEight from "../../assets/images/field-img-58.jpg";
import fieldImgEight from "../../assets/images/field-img-8.JPG";
import fieldImgNine from "../../assets/images/field-img-9.jpg";
import fieldImgTen from "../../assets/images/field-img-10.jpg";
import fieldImgTwentyNine from "../../assets/images/field-img-29.jpg";
import fieldImgThirty from "../../assets/images/field-img-30.jpg";
import fieldImgThirtyOne from "../../assets/images/field-img-31.PNG";
import fieldImgThirtyTwo from "../../assets/images/field-img-32.jpg";
import fieldImgThirtyThree from "../../assets/images/field-img-33.jpg";
import fieldImgThirtyFive from "../../assets/images/field-img-35.jpg";
import fieldImgThirtySeven from "../../assets/images/field-img-37.jpg";
import fieldImgThirtyEight from "../../assets/images/field-img-38.jpg";
import fieldImgThirtyNine from "../../assets/images/field-img-39.PNG";
import fieldImgFortyOne from "../../assets/images/field-img-41.PNG";
import fieldImgFortyTwo from "../../assets/images/field-img-42.PNG";
import fieldImgFortyThree from "../../assets/images/field-img-43.jpg";
import fieldImgFortyFour from "../../assets/images/field-img-44.jpg";
import fieldImgFortyFive from "../../assets/images/field-img-45.jpg";
import fieldImgFortySix from "../../assets/images/field-img-46.PNG";
import fieldImgFortySeven from "../../assets/images/field-img-47.jpg";
import fieldImgFortyEight from "../../assets/images/field-img-48.jpg";
import fieldImgFortyNine from "../../assets/images/field-img-49.jpg";
import fieldImgFifty from "../../assets/images/field-img-50.JPG";
import fieldImgFiftyOne from "../../assets/images/field-img-51.JPG";
import fieldImgFiftyTwo from "../../assets/images/field-img-52.jpg";
import fieldImgFiftyThree from "../../assets/images/field-img-53.jpg";
import fieldImgFiftyFour from "../../assets/images/field-img-54.jpg";
import fieldImgFiftyFive from "../../assets/images/field-img-55.jpg";
import fieldImgFiftySix from "../../assets/images/field-img-56.jpg";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();
  const projectData = [
    {
      img: fieldImgFiftyEight,
      title: "Penkivil Street, Bondi",
      completed: "2024",
      projectValue: "$2.1 Million",
      bulletPoints: [
        "Waterproofing WPM 1000",
        "Cavity Flashing Replacement",
        "Concrete Spalling",
        "Balustrade Replacement - Frameless Glass and Rendered Masonry",
        "Door Replacement",
        "Facade Coating",
        "Stitching",
        "Tiling",
      ],
      images: [
        {
          src: fieldImgFiftyFive,
          original: fieldImgFiftyFive,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFiftySix,
          original: fieldImgFiftySix,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgThirtyFive,
          original: fieldImgThirtyFive,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFiftyEight,
          original: fieldImgFiftyEight,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFiftyTwo,
          original: fieldImgFiftyTwo,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgTwentyNine,
      title: "Romsey Street, Waitara",
      completed: "Mid 2024",
      projectValue: "$190K",
      bulletPoints: [
        "Waterproofing - FETRA 807",
        "Relining Box Gutter - FETRA 810V",
        "Paving Tiles Over Pedestals",
        "Doors & Windows Replacement",
        "FC Cladding Replacement",
        "Facade Coating",
      ],
      images: [
        {
          src: fieldImgFiftyThree,
          original: fieldImgFiftyThree,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFiftyFour,
          original: fieldImgFiftyFour,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgThirty,
      title: "Oliver rd, Chatswood",
      completed: "2023",
      projectValue: "$750K",
      bulletPoints: [
        "Waterproofing WPM 1000",
        "Tiling",
        "Spalling",
        "Balustrade Replacement",
        "Render Repairs",
        "Facade Coating",
        "External Painting",
        "Bathroom Renovations (9 off)",
      ],
      images: [
        {
          src: fieldImgFortySeven,
          original: fieldImgFortySeven,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyEight,
          original: fieldImgFortyEight,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyNine,
          original: fieldImgFortyNine,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFifty,
          original: fieldImgFifty,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFiftyOne,
          original: fieldImgFiftyOne,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyFive,
          original: fieldImgFortyFive,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgThirtyOne,
      title: "O'Reilly Street, Paramatta",
      completed: "2021",
      projectValue: "$550K",
      bulletPoints: [
        "Waterproof WPM 1000",
        "Cavity Flashing",
        "Render Repairs",
        "Facade Coating",
        "Tiling Planter Box Waterproofing",
        "Bathroom Renovations (6 off)",
      ],
      images: [
        {
          src: fieldImgFortySix,
          original: fieldImgFortySix,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgThirtyOne,
          original: fieldImgThirtyOne,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgTen,
      title: "Lane Street, Wentworthville",
      bulletPoints: [],
      completed: "2022",
      projectValue: "100K",
      description:
        "Demolish Failed 2 x Major supporting columns of 3 storeys in collaboration with structural engineers.",
      images: [
        {
          src: fieldImgEight,
          original: fieldImgEight,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgNine,
          original: fieldImgNine,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgTen,
          original: fieldImgEight,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgThirtySeven,
      title: "Turramurra Avenue, Turramurra",
      completed: "2013",
      bulletPoints: [],
      projectValue: "$1 Million",
      description: `This project is in collaboration with unified and the original 
        award winning builder/developer and fire consultant. This building
        was completed in 2013 and won an award with Master Builders in 2014.
        Our very own George Farah was part of the team during the final stage
        of the construction and unified were re-engaged to oversee the replacement
        of the cladding. It should be noted that combustable cladding was an approved
        product. Installed on typical residential buildings as well as commercial and government buildings, 
        this scheme since its completion and apart from the cladding, has seen no defects apart from normal minor building 
        cracking, which was rectified by the original builders.`,

      images: [
        {
          src: fieldImgThirtyTwo,
          original: fieldImgThirtyTwo,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgThirtyEight,
          original: fieldImgThirtyEight,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgThirtyNine,
          original: fieldImgThirtyNine,
          width: 250,
          height: 250,
        },
      ],
    },
    {
      img: fieldImgThirtyThree,
      title: "Sutherland Cres, Darling Point",
      projectValue: "$340K",
      completed: "2024",
      description:
        "Completed in 2024, at a project value of $340,000, tasks completed include demolition, walls, renders, new kitchen, bathrooms, timber flooring, fire rated walls and stacks, and painting",
      bulletPoints: [
        "Demolition Walls",
        "Renders",
        "New Kitchen",
        "Bathrooms",
        "Timber Flooring",
        "Fire Rated Walls and Stacks",
        "Painting",
      ],
      images: [
        {
          src: fieldImgFortyOne,
          original: fieldImgFortyOne,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyTwo,
          original: fieldImgFortyTwo,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyThree,
          original: fieldImgFortyThree,
          width: 250,
          height: 250,
        },
        {
          src: fieldImgFortyFour,
          original: fieldImgFortyFour,
          width: 250,
          height: 250,
        },
      ],
    },
  ];

  const navigateToProject = (project) => {
    const tag = project.title.replace(",", "").replace(/\s+/g, "-");

    navigate("/projects/" + tag, {
      state: { project: { ...project } },
    });
  };

  return (
    <div className={classes["projects"]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects | Unified Building Group</title>
      </Helmet>
      <Navbar />
      <Hero image={fieldImgThirtyFive} title={"Our Projects"} />
      <div className={classes["gallery-section"]}>
        <h2>Browse our projects</h2>
        <div className={classes["underline"]}></div>
        <div className={classes["gallery-grid"]}>
          {projectData.map((project) => (
            <div
              className={classes["gallery-item"]}
              key={project.title}
              onClick={() => navigateToProject(project)}
            >
              <img src={project.img} alt="" />
              <p>{project.title}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Projects;
