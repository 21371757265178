import React, { useState } from "react";
import { LuConstruction } from "react-icons/lu";
import { RxStarFilled } from "react-icons/rx";
import logo from "../../assets/images/logo-light.png";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaWindowClose } from "react-icons/fa";
import { useWindowSize } from "@uidotdev/usehooks";
import classes from "./Navbar.module.scss";

const Navbar = () => {
  const windowSize = useWindowSize();

  const navigate = useNavigate();
  const [state, setState] = useState({
    home: false,
    homeHover: false,
    aboutHover: false,
    services: false,
    projects: false,
    contact: false,
    menuOpen: false,
  });

  const toggleMenuOpen = () => {
    setState((prevState) => {
      return {
        ...prevState,
        menuOpen: !prevState.menuOpen,
      };
    });
  };

  return (
    <div className={classes["navbar"]}>
      <div className={classes["top-bar"]}>
        <div className={classes["top-bar-wrapper"]}>
          <LuConstruction />
          {!state.aboutHover &&
            !state.home &&
            !state.services &&
            !state.projects &&
            !state.contact && <span>Best in the business</span>}
          {state.aboutHover && <span>Learn more about us!</span>}
          {state.services && <span>Check out our services.</span>}
          {state.projects && <span>View our projects.</span>}
          {state.contact && <span>Get in touch!</span>}
          {state.home && <span>Back to main page</span>}
          <RxStarFilled />
          <RxStarFilled />
          <RxStarFilled />
          <RxStarFilled />
          <RxStarFilled />
        </div>
      </div>
      <div className={classes["container"]}>
        <div className={classes["brand"]}>
          <img
            src={logo}
            alt="Unified Building Group Logo"
            onClick={() => {
              navigate("/");
            }}
            onMouseOver={() =>
              setState((prevState) => {
                return {
                  ...prevState,
                  home: true,
                };
              })
            }
            onMouseOut={() =>
              setState((prevState) => {
                return {
                  ...prevState,
                  home: false,
                };
              })
            }
          />
        </div>
        <div className={classes["inner"]}>
          {!state.menuOpen && (
            <FiMenu
              className={classes["mobile-menu"]}
              onClick={toggleMenuOpen}
            />
          )}
          {state.menuOpen && (
            <FaWindowClose
              className={classes["mobile-menu"]}
              onClick={toggleMenuOpen}
            />
          )}
          {(windowSize.width > 720 || state.menuOpen) && (
            <ul>
              <li
                onMouseOver={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      homeHover: true,
                    };
                  })
                }
                onMouseOut={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      homeHover: false,
                    };
                  })
                }
              >
                <Link to={"/"}>Home</Link>
                <div
                  className={`${classes["underline"]} ${
                    state.homeHover ? classes["underline-hov"] : ""
                  }`}
                />
              </li>
              <li
                onMouseOver={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      aboutHover: true,
                    };
                  })
                }
                onMouseOut={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      aboutHover: false,
                    };
                  })
                }
              >
                <Link to={"/about"}>About</Link>
                <div
                  className={`${classes["underline"]} ${
                    state.aboutHover ? classes["underline-hov"] : ""
                  }`}
                />
              </li>
              <li
                onMouseOver={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      services: true,
                    };
                  })
                }
                onMouseOut={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      services: false,
                    };
                  })
                }
              >
                <Link to={"/services"}>Services</Link>
                <div
                  className={`${classes["underline"]} ${
                    state.services ? classes["underline-hov"] : ""
                  }`}
                />
              </li>
              <li
                onMouseOver={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      projects: true,
                    };
                  })
                }
                onMouseOut={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      projects: false,
                    };
                  })
                }
              >
                <Link to={"/projects"}>Projects</Link>
                <div
                  className={`${classes["underline"]} ${
                    state.projects ? classes["underline-hov"] : ""
                  }`}
                />
              </li>
              <li
                onMouseOver={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      contact: true,
                    };
                  })
                }
                onMouseOut={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      contact: false,
                    };
                  })
                }
              >
                <Link to={"/contact"}>Contact</Link>
                <div
                  className={`${classes["underline"]} ${
                    state.contact ? classes["underline-hov"] : ""
                  }`}
                />
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
